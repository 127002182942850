<template>
    <b-container fluid class="p-0">
    <slidebar />
    <wizard />
    </b-container>
</template>

<script>
import Slidebar from '@/components/broadcast/Slidebar.vue';
import Wizard from '@/components/broadcast/Wizard.vue';

export default {
  components: {
    Slidebar,
    Wizard
  },
    // beforeRouteLeave(to, from, next) {
    //     if (this.to) {
    //         next();
    //     } else {
    //         this.to = to;
    //         this.$root.$emit('opemmodaldesejasalvar', 'xxxx');
    //         this.$root.$on('opemmodaldesejasair', data => {
    //             next();
    //         });
    //     }
    // },
    methods:{
        showModalDeseja() {
            this.$refs['my-modal-desejasair'].show();
        },
        hideModalDeseja() {
            this.$refs['my-modal-desejasair'].hide();
        },
    },
    mounted(){
        
        
		
    }
  
  
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

#bar {
    color: #FFF;
    display: flex;
    top: 25px;
    position: relative;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: #00e4a0 !important;
}

#modal-list .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #00E4A0;
}

#modal-list .modal-body {
    padding: 10px;
}

#modal-list .modal-dialog.modal-md {
    max-width: 1000px;
}

#modal-list .nav-link {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #333;
}

#trasmicao .daterangepicker.dropdown-menu.ltr.show-calendar.show-ranges.show-weeknumbers.openscenter.linked {
    margin-left: 186px;
    margin-top: 22px;
}

#modal-list .modal-header {
    border: none !important;
}

#remetente select.select-filter-options.custom-select {
    // background-color: #00e4a00d;
    // border: 1px solid #00E4A0;
    border: 1px solid #EDEDF0;
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 5px;
    width: 388px;
    height: 55px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

#remetente select.select-filter-options.custom-select:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#remetente select.select-filter-options.custom-select:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

select#select-teste-ab {
    height: 50px;
    border: 1px solid #EDEDF0;
    background-color: #fff;
    box-sizing: border-box;
    width: 95%;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
}

select#select-teste-ab:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

select#select-teste-ab:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#modal-remetente {
    position: fixed;
    width: 370px;
    height: 400px;
    left: 74%;
    top: 9%;
}

#modal-remetente .addremetenteinput {
    width: 280px;
    height: 40px;
    border: 0.5px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 3px;
    margin-bottom: 20px;
	font-family: Montserrat;
	font-style: normal;
	font-weight: normal;
	font-size: 13px;
}

#modal-remetente button.close {
    color: #000 !important;
    opacity: inherit;
}

#modal-remetente .modal-content {
	width: 315px;
    height: 90% !important;
    margin: inherit;
    margin-right: 4% !important;
}

#modal-remetente .modal-header {
    border: none;
    margin-bottom: -15px;
}

#trasmicao .multiselect.input-maior-select .multiselect__tags {
    width: 369px;
}

#trasmicao .multiselect__tags {
    width: 335px;
    padding-top: 11px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    font-size: 13px;
    overflow: auto;
}

#trasmicao .multiselect__tags:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#trasmicao .multiselect__tags:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#automacao .multiselect__tags {
    width: 315px;
    padding-top: 11px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    overflow: auto;
}

div#modal-preview {
    width: 100%;
    height: 100%;
}

#modal-list thead {
    background: #f5f2fc80;
}

#modal-list .table thead th {
    border: none;
}

#modal-list .table-sm th, .table-sm td {
    border: none;
    padding-top: 10px;
}

#modal-preview .modal-dialog.modal-md {
    width: 466px !important;
    height: 565px !important;
    position: relative !important;
    top: 46% !important;
    -webkit-transform: translateY(-54%) !important;
    transform: translateY(-51%) !important;
}
#modal-lead-limit .modal-dialog.modal-md {
    width: 466px !important;
    height: 565px !important;
    position: relative !important;
    top: 46% !important;
    -webkit-transform: translateY(-54%) !important;
    transform: translateY(-51%) !important;
}

#modal-lead-limit .modal-content{
    width: 620px !important;
}

#modal-lead-limit .modal-body{
    text-align: center;
}

#modal-preview .modal-header {
    border: none;
}

#modal-preview .modal-content{
    height: 89% !important;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

#automacao .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #00e4a0;
    background-color: #00e4a0;
}

#automacao .multiselect__tags:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#automacao .multiselect__tags:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#trasmicao select.select-filter-options.custom-select {
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    width: 330px;
    display: block;
}

#automacao input.input-auto-style.input-select-action.form-control {
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    background-color: #fff;
}

#automacao input.input-auto-style.input-select-action.form-control:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#automacao input.input-auto-style.input-select-action.form-control:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#automacao input.input-auto-style.input-number-auto.form-control {
    border: 1px solid #EDEDF0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    background-color: #fff;
}

#automacao textarea.input-auto-style.form-control {
    border: 1px solid #EDEDF0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.2px;
    color: #000;
    height: 50px;
    font-size: 13px;
    background-color: #fff;
    width: 280px;
}

#automacao input.input-auto-style.input-number-auto.form-control:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#automacao input.input-auto-style.input-number-auto.form-control:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#trasmicao .vue-slider-process {
    background-color: #00E4A0;
    border-radius: 15px;
}

#trasmicao .vue-slider-dot-handle {
    cursor: pointer;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    -webkit-box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    box-shadow: 0.5px 0.5px 2px 1px rgba(0, 0, 0, 0.32);
    background: #CCFAEC;
    border: 1px solid #00E4A0;
    box-sizing: border-box;
    border-radius: 50%;
}

#trasmicao .vue-slider-dot-tooltip-inner {
    font-size: 14px;
    white-space: nowrap;
    padding: 2px 5px;
    min-width: 20px;
    text-align: center;
    color: #000;
    border-radius: 5px;
    border-color: #fff;
    background-color: #fff;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin-bottom: -5px;
}

#trasmicao .multiselect__select {
    top: 7px !important;
}

#trasmicao .multiselect__input, .multiselect__single{
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
}

#trasmicao .form-control.reportrange-text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
    height: 50px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 16px;
}

#trasmicao .form-control.reportrange-text:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#trasmicao .form-control.reportrange-text:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#trasmicao .multiselect__content-wrapper {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
}

#trasmicao .multiselect__placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
}

#automacao .multiselect__content-wrapper {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
}

#automacao .multiselect__placeholder {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000;
}

#trasmicao select.select-filter-options.custom-select:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#trasmicao select.select-filter-options.custom-select:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#envio .form-control.reportrange-text {
    display: block;
    margin-top: 0px;
    width: 221px;
    border: 1px solid #EDEDF0;
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    height: 50px;
    font-size: 13px;
    letter-spacing: 0.2px;
    color: #000;
    padding: 15px;
}

#envio .form-control.reportrange-text:hover {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}

#envio .form-control.reportrange-text:focus {
    background-color: #00e4a00d;
    border: 1px solid #00E4A0;
}


</style>